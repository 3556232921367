jQuery(function ($) {
  $(".home .news-category__link").on("click", function (e) {
    e.preventDefault();

    $(".home .news-category__link").removeClass("active");
    $(this).addClass("active");

    const termId = $(this).data("term-id");
    $.ajax({
      url: my_ajax_object.ajax_url,
      type: "POST",
      data: {
        action: "filter_news_by_category",
        term_id: termId,
      },
      dataType: "html",
      success: function (response) {
        $(".home .news-list-content").html(response);
      },
      error: function (error) {
        console.error(error);
      },
    });
  });
});
