// jQuery
jQuery(function ($) {
  // ページトップ スムーススクロール
  $("#pageTop").click(function () {
    var adjust = 0;
    var speed = 400;
    var href = jQuery(this).attr("href");
    var headerHight = 100;
    var target = jQuery(href == "#" || href == "" ? "html" : href);
    console.log(jQuery(this).attr("href"));
    var position = target.offset().top + adjust - headerHight;
    jQuery("body,html").animate({ scrollTop: position }, speed, "swing");
    return false;
  });

  // ハンバーガーメニュー開閉
  $(".hamburger-btn").click(function () {
    $(this).toggleClass("active");
    $(".hamburger-menu").toggleClass("active");
  });
  // ハンバーガーメニュー内 アコーディオン開閉
  $(".hamburger-menu .js-accordion-btn").click(function () {
    $(this).toggleClass("active");
    $(this).closest(".gnav-sp__item-title").next(".js-accordion-content").slideToggle();
  });
  // FAQアコーディオン開閉
  $(".faq-question").click(function () {
    $(this).find(".js-accordion-btn").toggleClass("active");
    $(this).next(".js-accordion-content").slideToggle();
  });

  // 外部リンクアイコンを挿入
  const svgCode = `
  <svg xmlns="http://www.w3.org/2000/svg" width="12.811" height="12.811" viewBox="0 0 12.811 12.811">
    <g id="Icon_feather-external-link" data-name="Icon feather-external-link" transform="translate(-3.75 -3.439)">
      <path id="パス_4505" data-name="パス 4505" d="M13.667,13.278v3.667a1.222,1.222,0,0,1-1.222,1.222H5.722A1.222,1.222,0,0,1,4.5,16.944V10.222A1.222,1.222,0,0,1,5.722,9H9.389" transform="translate(0 -2.667)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
      <path id="パス_4506" data-name="パス 4506" d="M22.5,4.5h3.667V8.167" transform="translate(-10.667 0)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
      <path id="パス_4507" data-name="パス 4507" d="M15,11.222,21.722,4.5" transform="translate(-6.222 0)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
    </g>
  </svg>
  `;
  $(".external-icon").append(svgCode);

  // ヘッダー スクロール fixed付与
  const fixedTriggerElement = document.querySelector("[data-header-fixed-trigger]");
  const headerElement = document.querySelector(".header");

  if (fixedTriggerElement) {
    // fixedTriggerElement が存在するかどうかをチェック
    const handleHeaderFixed = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          headerElement.classList.add("is-fixed");
        } else {
          headerElement.classList.remove("is-fixed");
        }
      },
      {
        threshold: 0, // fixedTriggerElement が少しでも見えなくなったら is-fixed を付与
      }
    );
    handleHeaderFixed.observe(fixedTriggerElement);
  }

  // underline-animation
  $(window).on("load", function () {
    $(".top-mv__lead-text, .underline-animation").addClass("show");
  });

  // yubinbango.js selectタグのp-region自動選択
  $(".p-postal-code").on("change", function () {
    var postalCode = $(this).val();
    var address = new YubinBango(postalCode);
    address.getPrefecture(function (prefecture) {
      $(".p-region").val(prefecture);
    });
  });


  // タブ切り替え
  $('.js-tab').on('click', function () {
    var idx = $('.js-tab').index(this);
    // $(this).addClass('active').siblings('.js-tab').removeClass('active');

    var panelArea = $(this).closest('.js-tab_area').next('.js-panel_area');
    var panels = panelArea.find('.js-panel');
    panels.removeClass('show');
    panels.eq(idx).addClass('show');
  });
});

document.addEventListener("DOMContentLoaded", () => {
  setTimeout(() => {
    const idPrefixes = [
      { prefix: "cf7msm_seikyu", processElements: true },
      { prefix: "cf7msm_toiawase", processElements: false },
    ];

    idPrefixes.forEach(({ prefix, processElements }) => {
      const individualCorporate = document.getElementById(`${prefix}__individual-corporate`);
      if (!individualCorporate) return;

      const clientType = individualCorporate.textContent.trim();
      const blockClass = clientType === "個人" ? ".individual-block" : ".corporate-block";
      const block = document.querySelector(blockClass);

      if (block) {
        block.style.display = "block";
      }

      if (processElements) {
        const elementIds = [`${prefix}__corporate-purpose-request-documents`, `${prefix}__corporate-location-request-documents`];

        elementIds.forEach((id) => {
          const element = document.getElementById(id);
          if (element) {
            element.innerHTML = element.textContent.replace(/,/g, "<br>");
          }
        });
      }
    });
  }, 500);
});

// micromodal
// MicroModal.init({
//   disableScroll: true,
//   awaitOpenAnimation: true,
//   awaitCloseAnimation: true,
// });

document.addEventListener("DOMContentLoaded", function () {
  // Micromodal が読み込まれていないなら retry（←ここポイント）
  function initMicromodal() {
    if (typeof MicroModal !== "undefined") {
      MicroModal.init({
        disableScroll: true,
        awaitOpenAnimation: true,
        awaitCloseAnimation: true,
      });
    } else {
      // Micromodal がまだ読み込まれてなければ 100ms後に再トライ（最大10回まで）
      if (window.__micromodalRetryCount === undefined) window.__micromodalRetryCount = 0;
      if (window.__micromodalRetryCount++ < 10) {
        setTimeout(initMicromodal, 100);
      } else {
        console.error("MicroModal is not defined after retrying.");
      }
    }
  }

  initMicromodal();
});



// ページ外リンク
// ブラウザの自動スクロールを防止
if ("scrollRestoration" in history) {
  history.scrollRestoration = "manual";
}

window.addEventListener("load", function () {
  if (window.location.hash) {
    // ページ読み込み後、ブラウザがデフォルトのスクロールを行わないように少し待つ
    setTimeout(function () {
      try {
        var header = document.querySelector("header");
        var headerHeight = header ? header.offsetHeight : 0;

        var targetElement = document.querySelector(window.location.hash);
        if (targetElement) {
          var elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
          var offsetPosition = elementPosition - headerHeight;

          window.scrollTo({
            top: offsetPosition,
            behavior: "auto",
          });
        }
      } catch (error) {
        console.error("スクロール調整中にエラーが発生しました:", error);
      }
    }, 0);
  }
});
